
.heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: var(--sectionMarginVertical);
    margin-bottom: var(--sectionMarginVertical);
}

.headingTitle {
    margin-top: 8px;
    font-size: 28px;
    font-weight: bold;
    color: var(--lukit-primary-color);
}

.benefitsSection {
    display: flex;
    flex-direction: column;
    margin: 0 var(--sectionMarginHorizontal);
    margin-bottom: 10px;
}

.benefitsTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.benefit {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.benefitText {
    margin-right: 5px;
}

.disclaimerSection {
    display: flex;
    flex-direction: column;
    margin: 0 var(--sectionMarginHorizontal);
    margin-bottom: var(--sectionMarginVertical);
}

.buttonText {
    display: flex;
    flex-direction: column;
}

.premiumText {
    font-weight: bold;
}

.premiumButton {
    width: 100%;
}
