

.heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: var(--sectionMarginVertical);
    margin-bottom: var(--sectionMarginVertical);
    font-size: 30px;
    font-weight: bold;
    padding: 0 var(--sectionMarginHorizontal);
}

.headingTitle {
    margin-top: 8px;
    font-size: 28px;
    font-weight: bold;
    color: var(--lukit-primary-color);
}

.body {
    text-align: center;
    margin-bottom: var(--sectionMarginVertical);
    padding: 0 var(--sectionMarginHorizontal);
}

.logout{
    text-align: center;
    margin-bottom: var(--sectionMarginVertical);
}
