
.heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: var(--sectionMarginVertical);
    margin-bottom: var(--sectionMarginVertical);
    font-size: 30px;
    font-weight: bold;
}

.subheading {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 0 var(--sectionMarginHorizontal);
    margin-bottom: var(--sectionMarginVertical);
}

.form {
    text-align: center;
    align-items: center;
}

.formFields {
    margin: 0 var(--sectionMarginHorizontal);
    margin-bottom: var(--sectionMarginVertical);
}

.formField {
    width: 100%
}

.phoneRow {
    display: flex;
    flex-direction: row;
}

.countryCodeField {
    min-width: 100px !important;
}

.otpContainer {
    justify-content: center;
    flex-direction: row-reverse;
}

.otpInput {
    width: 55px !important;
    height: 55px;
    border-radius: 100px;
    outline: none;
    background-color: #ffffff;
    border-style: solid;
    border-width: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
    color: var(--lukit-secondary-color);
    margin: 0 10px;
    font-size: 23px;
    margin-bottom: var(--sectionMarginVertical);
}

.otpFocus {
    border-radius: 100px;
    border-color: var(--lukit-secondary-color);
    border-width: 2px;
}

.otpError {
    border-color: red;
    border-width: 2px;
}

.textSpacer {
    margin: 5px 0;
    text-align: center;
}

.serverError {
    padding: 0 20px 20px;
    text-align: center !important;
}

.spinner {
    justify-content: center;
    align-items: center;
    text-align: center;
}
