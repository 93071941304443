.heading {
    text-align: center;
    align-items: center;
    margin-top: var(--sectionMarginVertical);
    margin-bottom: var(--sectionMarginVertical);
    font-size: 30px;
    font-weight: bold;
}

.formFieldsSection {
    /*margin: 0 100px;*/
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.form{
    text-align: right;
}

.formControl {
    margin-bottom: var(--sectionMarginVertical) !important;
}

#creditCardField {
}

#cardCVVField {
    width: 100%;
    margin-right: 22px;
}

#cardExpirationField {
    width: 100%;
}

.PayMeField {
    height: 56px;
    border: 1px solid threedface;
    border-radius: 4px;
    padding-right: 14px;
    box-sizing: border-box;
}

.PayMeField:hover {
    border: 1px solid black;
}

.PayMeFieldFocused {
    border: 2px solid var(--lukit-primary-color);
}

.PayMeFieldInvalid {
    border: 1px solid red !important;
}

.row {
    display: flex;
    flex-direction: row;
}

.loaderRow {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: var(--sectionMarginVertical);
}

.errorText{
    text-align: right;
    font-size: 0.75rem;
    margin-right: 14px;
    margin-top: 3px;
}

.PayMeErrorSection{
    text-align: center;
    margin-bottom: var(--sectionMarginVertical);
}

.paymentConfirmation{
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: var(--sectionMarginVertical);
}

.buttonText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.premiumText {
    font-weight: bold;
}

.premiumButton {
    width: 100%;
}


@media screen and (max-width: 600px) {
    .formFieldsSection {
        margin: 0 50px;
    }
}
