:root {
    --lukit-primary-color: #3ec7af;
    --lukit-secondary-color: #1de9b6;
    --branded-color: #ff1051;
    --sectionMarginVertical: 30px;
    --sectionMarginHorizontal: 20px;
}

@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

html {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Rubik", serif !important;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    text-align: right;
    max-width: 800px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: var(--sectionMarginVertical) var(--sectionMarginHorizontal)
}

.errorText {
    color: red;
}

.textButton {
    text-decoration: underline;
    cursor: pointer;
}

.MuiFormHelperText-root {
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: right;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}
